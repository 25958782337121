// Constants
import { ADDONS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'EditConfigs',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        showOnInit: false,
        slideDuration: 5
      },
      formFieldsValidations: {
        showOnInit: {
          required: 'Campo obligatorio'
        },
        slideDuration: {
          required: 'Campo obligatorio',
          integer: 'Debes indicar un número entero'
        }
      },
      // Others
      currencyData: []
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const featuredItemsConfig = get(this.placeAddonsSetupByUser, ADDONS.featuredItems, {})

      this.formFields = {
        showOnInit: get(featuredItemsConfig, 'showOnInit', this.formFields.showOnInit),
        slideDuration:
          get(featuredItemsConfig, 'slideDuration', this.formFields.slideDuration * 1000) / 1000
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      const addOns = [
        {
          id: ADDONS.featuredItems,
          configFields: {
            ...this.formFields,
            slideDuration: this.formFields.slideDuration * 1000 // lo almacenamos en ms
          }
        }
      ]

      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      showOnInit: {
        required
      },
      slideDuration: {
        required,
        integer
      }
    }
  }
}
