// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import CardContainer from '@/components/ui/CardContainer'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import FeaturedItemsList from '../../components/elements/FeaturedItemsList'
import FeaturedItemsConfig from '../../components/elements/FeaturedItemsConfig'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'FeaturedItemsByTabs',
  components: {
    CardContainer,
    VuetifyToolBar,
    VuetifyTabs
  },
  mixins: [uiMixin],
  data() {
    return {
      items: [
        {
          label: 'Destacados',
          component: FeaturedItemsList
        },
        {
          label: 'Configuración',
          component: FeaturedItemsConfig
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  methods: {
    /**
     * Creamos nuevo "Destacado"
     */
    handleCreateFeaturedItem() {
      this.routerPushTo({
        name: 'featuredItem'
      })
    }
  }
}
