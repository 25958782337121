// Utils
import { isNil, get } from 'lodash'
// Filters
import { formatDate } from '@/filters'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'FeaturedItemsListItem',
  props: {
    item: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    }
  },
  filters: { formatDate },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Posee algún filtro de fecha u hora
     *
     * @return {boolean}
     */
    hasDateFilter() {
      return (
        this.item.dateFrom ||
        this.item.timeFrom ||
        this.hasTimeFilter(this.item.dateTo) ||
        this.hasTimeFilter(this.item.timeTo)
      )
    },
    /**
     * Obtenemos los estilos de la historia para mostrar
     * en el círculo
     *
     * @return {String}
     */
    itemStyles() {
      let styles = `background-color:${this.item.backgroundColor};`

      if (this.item.backgroundImage) {
        styles += `background-image:url(${this.item.backgroundImage});`
      }

      return styles
    }
  },
  methods: {
    /**
     * Handle click on item
     *
     * @param {string} id - item id
     */
    handleClickItem(id) {
      this.$emit('onClickItem', id)
    },
    /**
     * El objeto "time" no está vacío
     *
     * @param {object} time
     * @return {boolean}
     */
    hasTimeFilter(time) {
      return !isNil(time) && get(time, 'HH', '') !== '' && get(time, 'mm', '') !== ''
    },
    /**
     * Devuelve el formato de la hora
     *
     * @param {object} time
     * @return {string}
     */
    formatTime(time) {
      return this.hasTimeFilter(time) ? `${time.HH}:${time.mm}h` : '-'
    }
  }
}
