// Components
import CardContainer from '@/components/ui/CardContainer'
import DraggableList from '@/components/ui/DraggableList'
import FeaturedItemsListItem from '../FeaturedItemsListItem'
import FeaturedItemsListKeys from '../FeaturedItemsListKeys'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Services
import {
  getEveryFeaturedItemsByPlaceId,
  updateFeaturedItemById
} from '../../../services/featuredItem'
// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'FeaturedItemsList',
  components: {
    CardContainer,
    DraggableList,
    FeaturedItemsListItem,
    FeaturedItemsListKeys,
    SectionInformationBox,
    VuetifyContentLoading
  },
  mixins: [uiMixin],
  data() {
    return {
      items: [],
      processingRequest: true,
      onlyPublished: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Available items (with filter)
     *
     * @return {array}
     */
    availableItems() {
      if (this.everyItemIsPublished) {
        return this.items
      }

      return this.onlyPublished ? this.items.filter((item) => item.isPublished) : this.items
    },
    /**
     * Every items is "published"
     *
     * @return {boolean}
     */
    everyItemIsPublished() {
      return this.items.every((item) => item.isPublished)
    },
    /**
     * Get the options to show the screen when there are not items
     *
     * @return {object}
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('onboarding/dishes.svg'),
        title: 'Destacados / Fuera de carta',
        description: 'Aún no has creado ningún destacado',
        buttonLabel: 'Crear destacado ¡ahora!'
      }
    },
    /**
     * Opciones para el botón de acción de la barra de opciones
     *
     * @return {Object}
     */
    vuetifyToolBarButtonVOptions() {
      return {
        color: 'secondary',
        icon: false,
        outlined: true
      }
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        try {
          await Promise.all(
            items.map(async (item, index) => {
              await updateFeaturedItemById({
                id: item.id,
                order: index
              })
            })
          )
        } catch (error) {
          this.handleError(error.message)
        }
      }
    },
    /**
     * handle click on list item
     */
    handleClickToolBarAction() {
      this.goToDetailPage()
    },
    /**
     * handle click on list item
     *
     * @param {string} id - id register in DB
     */
    handleClickItem(id) {
      this.goToDetailPage(id)
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Ir a la vista detalle
     *
     * @param {String} id
     */
    goToDetailPage(id = null) {
      this.routerPushTo({
        name: 'featuredItem',
        params: id ? { id } : {}
      })
    },
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      // Obtenemos Los items del listado
      await this.setItems()
    },
    /**
     * Obtenemos los items destacados
     */
    async setItems() {
      try {
        this.processingRequest = true
        this.items = await getEveryFeaturedItemsByPlaceId(this.placeData.id)
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    }
  }
}
